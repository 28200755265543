import React from 'react'

import { Card, Col, Row, Container } from 'reactstrap'

import upwork from '../assets/img/icons/common/upwork.png'

import SocialLinks from '../components/SocialLinks'

const GithubProfileCard = ({ prof }) => {
  return (
    <Card className="section-lg bg-gradient-info shadow-lg border-0">
      <Container className="">
        <div className="p-2">
          <Row className="">
            <Col className="order-lg-2" lg="4">
              <img
                src={prof.avatar_url}
                style={{ width: '200px' }}
                alt=""
                className="rounded-circle img-center img-fluid shadow shadow-lg--hover mb-4"
              />
            </Col>
            <Col lg="8" className="order-lg-1">
              <h2 className="text-white">
                Reach out to me via
                <a target='blank' href="https://www.upwork.com/freelancers/~01ccf074e5613d4d1f">
                  <img src={upwork} alt="" style={{ width: '50px' }} />
                </a>
              </h2>

              <p className="text-white mt-3">{prof.bio}</p>
              <div className="my-3 icon-shape bg-gradient-white shadow rounded text-info">
                <i className="ni ni-pin-3 text-info mr-2" />
                {prof.location}
              </div>
              <SocialLinks />
            </Col>
          </Row>
        </div>
      </Container>
    </Card>
  )
}

export default GithubProfileCard
