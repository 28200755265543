import emoji from 'react-easy-emoji'

import formbird from './assets/img/icons/common/formbird.png'
import site from './assets/img/icons/common/site.png'
import t360 from './assets/img/icons/common/t360.png'
import flood from './assets/img/icons/common/flood.svg'
import element from './assets/img/icons/common/element.png'
import katalon from './assets/img/icons/common/katalon.png'
import kobiton from './assets/img/icons/common/kobiton.svg'
import agreementExpress from './assets/img/icons/common/agreementExpress.svg'
import LNI from './assets/img/icons/common/LNI.png'

export const greetings = {
  name: 'phileba',
  description:
    'I love exploring new technologies, I like to stay on top of latest trends.',
}

export const openSource = {
  githubUserName: 'lebaphi',
}

export const contact = {}

export const socialLinks = {
  facebook: 'https://www.facebook.com/DustinLe2605',
  instagram: 'https://www.instagram.com/phi.le.ba',
  github: 'https://github.com/lebaphi',
  linkedin: 'https://www.linkedin.com/in/phi-le-ba-7a1753138',
  upwork: 'https://www.upwork.com/freelancers/~01ccf074e5613d4d1f',
}

export const skillsSection = {
  title: 'What I do',
  subTitle: 'CRAZY FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK',
  skills: [
    emoji('⚡ Develop highly interactive Front end / Back end'),
    emoji('⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks'),
    emoji(
      '⚡ Integration of third party services such as Firebase / AWS / Heroku'
    ),
  ],

  softwareSkills: [
    {
      skillName: 'html-5',
      fontAwesomeClassname: 'vscode-icons:file-type-html',
    },
    {
      skillName: 'css3',
      fontAwesomeClassname: 'vscode-icons:file-type-css',
    },
    {
      skillName: 'sass',
      fontAwesomeClassname: 'logos:sass',
    },
    {
      skillName: 'java',
      fontAwesomeClassname: 'logos:java',
    },
    {
      skillName: 'JavaScript',
      fontAwesomeClassname: 'logos:javascript',
    },
    {
      skillName: 'TypeScript',
      fontAwesomeClassname: 'logos:typescript-icon',
    },
    {
      skillName: 'nodejs',
      fontAwesomeClassname: 'logos:nodejs-icon',
    },
    {
      skillName: 'php',
      fontAwesomeClassname: 'logos:php',
    },
    {
      skillName: 'reactjs',
      fontAwesomeClassname: 'vscode-icons:file-type-reactjs',
    },
    {
      skillName: 'angular',
      fontAwesomeClassname: 'vscode-icons:file-type-angular',
    },
    {
      skillName: 'ionic',
      fontAwesomeClassname: 'logos:ionic-icon',
    },
    {
      skillName: 'vue',
      fontAwesomeClassname: 'logos:vue',
    },
    {
      skillName: 'npm',
      fontAwesomeClassname: 'vscode-icons:file-type-npm',
    },
    {
      skillName: 'yarn',
      fontAwesomeClassname: 'logos:yarn',
    },
    {
      skillName: 'sql-database',
      fontAwesomeClassname: 'vscode-icons:file-type-sql',
    },
    {
      skillName: 'postgres',
      fontAwesomeClassname: 'logos:postgresql',
    },
    {
      skillName: 'redis',
      fontAwesomeClassname: 'logos:redis',
    },
    {
      skillName: 'mongoDB',
      fontAwesomeClassname: 'vscode-icons:file-type-mongo',
    },
    {
      skillName: 'aws',
      fontAwesomeClassname: 'logos:aws',
    },
    {
      skillName: 'firebase',
      fontAwesomeClassname: 'logos:firebase',
    },
    {
      skillName: 'git',
      fontAwesomeClassname: 'logos:git-icon',
    },
    {
      skillName: 'docker',
      fontAwesomeClassname: 'logos:docker-icon',
    },
    {
      skillName: 'azure',
      fontAwesomeClassname: 'logos:microsoft-azure',
    },
    {
      skillName: 'helm',
      fontAwesomeClassname: 'vscode-icons:file-type-helm',
    },
    {
      skillName: 'kubenetes',
      fontAwesomeClassname: 'logos:kubernetes',
    },
    {
      skillName: 'terraform',
      fontAwesomeClassname: 'vscode-icons:file-type-terraform',
    },
    {
      skillName: 'kafka',
      fontAwesomeClassname: 'logos:kafka',
    },
    {
      skillName: 'spring',
      fontAwesomeClassname: 'logos:spring-icon',
    }
  ],
}

export const SkillBars = [
  {
    Stack: 'Frontend',
    progressPercentage: '85',
  },
  {
    Stack: 'Backend',
    progressPercentage: '80',
  },
  {
    Stack: 'Programming',
    progressPercentage: '90',
  },
  {
    Stack: 'DevOps',
    progressPercentage: '70',
  },
]

export const educationInfo = [
  {
    schoolName: 'International University - Vietnam National University',
    subHeader: 'Bachelor of Science in Computer Science',
    duration: '2007 - 2011',
    desc: 'Scholarship in 2010. Ranked top 10% in the program.',
  },
]

export const experience = [
  {
    role: 'Software Engineer',
    company: 'Fieldtec/Formbird',
    companylogo: formbird,
    date: 'July 2014 – July 2018',
    desc: 'The Formbird Development Platform is a comprehensive solution designed to support all types of businesses and meet the needs of our customers. The team and I built this system from scratch',
    descBullets: [
      'AngularJS',
      'NodeJS',
      'MongoDB',
      'ElasticSearch',
      'Gulp, Webpack',
      'Karma, Jasmine, Protractor',
    ],
  },
  {
    role: 'Fullstack Software Engineer',
    company: 'SITE',
    companylogo: site,
    date: 'Sep 2018 – Feb 2019',
    desc: `We built a platform for managing their client's websites on a large scale. The goal of the SITE workflow is to use a platform to identify fixes using AI and generate a repair report to send to the customer. I am responsible for the quality control of the code structure, refactoring the backend code, implementing new features`,
    descBullets: ['ReactJs', 'Hapi', 'MongoDB', 'S3', 'Jest'],
  },
  {
    role: 'Fullstack Software Engineer',
    company: '360Safety',
    companylogo: t360,
    date: 'Feb 2019 – Mar 2020',
    desc: `I build an entire system to manage resources / train / track user issues based on customer demands. This application is developed on PHP, Metronic theme and using MySQL for database, integrated with ASW S3, ASW SES, JWT, this application has been deployed on ASW EC2 machine.`,
    descBullets: ['JavaScript', 'PHP', 'MySql', 'S3', 'EC2'],
  },
  {
    role: 'Senior Software Engineer',
    company: 'Flood',
    companylogo: flood,
    date: 'Dec 2018 – April 2021',
    desc: `Flood IO is a load testing platform that executes globally-distributed performance tests from open source tools, including JMeter, Gatling, Selenium and Element. My team and I are in charge of handling all of the features of Organization, Team Permission, SSO using Auth0, integrate with Dynatrace, New Relic, App Dynamic, DataDog`,
    descBullets: ['Ember', 'TypeScript', 'Jest', 'Auth0'],
  },
  {
    role: 'Senior Software Engineer',
    company: 'Flood - Element',
    companylogo: element,
    date: 'Dec 2018 – April 2021',
    desc: `Flood Element is the first scalable, browser based load generation tool. Flood Element is the best way to get started with load testing. My team and I are in charge of handling all of the features of elements 1.5 and 2.0`,
    descBullets: [
      'TypeScript',
      'NodeJs',
      'Jest',
      'Puppeteer',
      'Playwright',
      'Docker',
    ],
  },
  {
    role: 'Senior Software Engineer',
    company: 'Katalon',
    companylogo: katalon,
    date: 'Mar 2021 – April 2021',
    desc: `Katalon is a powerful solution that helps you automate Web, API, Mobile, and Desktop apps — with smart analytics and CI/CD integrations. Work on POC project to develop new feature.`,
    descBullets: ['React', 'Java Spring Framework', 'Postgres SQL'],
  },
  {
    role: 'Senior Software Engineer',
    company: 'Kobiton',
    companylogo: kobiton,
    date: 'April 2021 – Oct 2021',
    desc: 'Kobiton is a mobile-first testing platform purpose-built for enterprises with the power to deliver faster release cycles and exceptional user experiences. As a mobile-centric testing platform, we pride ourselves on our top-tier customer service, platform capabilities, and product stability.',
    descBullets: ['React', 'NodeJS', 'MicroServices', 'ElasticSearch', 'Jest'],
  },
  {
    role: 'Senior Software Engineer',
    company: 'Agreement Express',
    companylogo: agreementExpress,
    date: 'Oct 2021 – Aug 2022',
    desc: 'Agreement Express is Software as a Service platform that automates the onboarding and underwriting processes for financial services firms.',
    descBullets: [
      'React',
      'Java Spring Framework',
      'MySQL',
      'Polymer',
      'MongoDB',
    ],
  },
  {
    role: 'Senior Software Engineer',
    company: 'LexisNexis',
    companylogo: LNI,
    date: 'Aug 2022 – Now',
    desc: 'LexisNexis is a part of the RELX corporation (formerly Reed Elsevier) that sells data analytics products and various databases that are accessed through online portals, including portals for computer-assisted legal research (CALR), newspaper search, and consumer information',
    descBullets: [
      'Java Spring Framework',
      'MicroServices',
      'Elastic Search',
      'Kafka',
      'Azure',
      'Docker Kubenetes',
      'Github CI/CD',
      'Helm',
      'Terraform',
      'JFrog',
    ],
  },
]

export const projects = [
  {
    name: 'lorem ipsum',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
  },
]
